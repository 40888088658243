.c-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1064px;
  margin: 30px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;

  @include mq($from: desktop) {
    margin-top: 80px;
    margin-bottom: 50px;
  }
}
