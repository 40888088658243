.c-page-logo__link {
 display: inline-block;
}

.c-page-logo__image {
  @include mq($from: desktop) {
    width: 257px;
    height: 57px;
  }
}
