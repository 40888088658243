.c-hero {
  position: relative;
  margin-bottom: 100px;

  @include mq($from: desktop) {
    margin-bottom: 220px;
  }
}

.c-hero__text {
  @include inuit-font-size(30px, 40px);
  font-weight: bold;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include mq($from: tablet, $until: desktop) {
    @include inuit-font-size(40px, 55px);
  }

  @include mq($until: desktop) {
    text-align: center;
  }

  @include mq($from: desktop) {
    @include inuit-font-size(55px, 70px);
    left: 50px;
    transform: translateY(-50%);
  }

  span {
    display: inline-block;
    background-color: $white-color;
    padding-right: 15px;
    white-space: nowrap;

    @include mq($until: tablet) {
      padding-left: 15px;
    }

    @include mq($from: tablet, $until: desktop) {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
}

.c-hero__slider {
  position: relative;

  @include mq($from: desktop) {
    margin-left: 234px;
  }
}

.c-hero__scroll {
  display: none;

  @include mq($from: desktop) {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50px, 100%);
    }
  }
}

.c-hero__scroll-label {
  @include inuit-font-size(10px);
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  transform-origin: bottom left;
  transform: rotate(-90deg) translateY(-10px);
}

.c-hero__scroll-link {
  display: block;
  text-decoration: none;
}
