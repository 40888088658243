.c-button {
  @include inuit-font-size(12px);
  background-color: $black-color;
  font-weight: 700;
  color: $white-color;
  text-decoration: none;
  border: 0;
  display: inline-block;
  padding: 14px;
  text-align: center;
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  @include mq($from: desktop) {
    padding: 24px;
  }
}

.c-button--primary {
  background-color: $primary-color;
}

.c-button--full {
  width: 100%;
}
