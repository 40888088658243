///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

$mq-breakpoints: (
    mobile: 480px,
    tablet: 768px,
    desktop: 1024px,
    wide: 1280px
);

$inuit-offsets: true; // Enable push and pull classes

// Fonts
$base-font-family: 'korolev', sans-serif;
