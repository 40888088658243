.c-box {
  background-color: #f8f8f8;
  padding: 50px 25px;

  @include mq($from: desktop) {
    padding: 80px 50px;
  }

  h2 {
    @include inuit-font-size(25px, 30px);
    font-weight: 700;
    letter-spacing: 1px;

    @include mq($from: desktop) {
      @include inuit-font-size(30px, 35px);
    }
  }
}

.c-box--middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-box--full {
  @include mq($from: desktop) {
    min-height: 100%;
  }
}

.c-button__footer {
  margin-top: 30px;

  @include mq($from: desktop) {
    margin-top: 50px;
  }
}
