.c-page-title {
  @include inuit-font-size(35px);
  font-weight: bold;
  margin-bottom: 40px;
  padding-bottom: 20px;
  position: relative;
  text-align: center;

  @include mq($from: tablet, $until: desktop) {
    @include inuit-font-size(45px);
  }

  @include mq($from: desktop) {
    @include inuit-font-size(65px);
    margin-bottom: 90px;
  }

  &::after {
    content: '';
    display: block;
    width: 140px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $primary-color;
  }
}
