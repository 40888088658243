/* ==========================================================================
   #WIDTHS
   ========================================================================== */

@include inuit-widths(12);

@media screen and (min-width: 768px) {
  @include inuit-widths(12, \@tablet);
}

@media screen and (min-width: 1024px) {
  @include inuit-widths(12, \@desktop);
}
