.c-project-item {
  display: block;
  background: no-repeat center center / cover;
  height: 180px;
  position: relative;
  overflow: hidden;

  @include mq($from: desktop) {
    height: 230px;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($white-color, 0.45);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    .c-project-item__label {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.c-project-item--large {
  @include mq($from: desktop) {
    height: 460px;
  }
}

.c-project-item__label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 1em;
  font-size: 1.5rem;
  font-weight: bold;
  color: $primary-color;
  background: linear-gradient(to top, rgba(#000, 0.6) 40px, transparent 200px);

  opacity: 0;
  transition: 200ms;
}
