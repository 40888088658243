html {
  overflow-y: visible;
}

html,
body {
  height: 100%;
}

body {
  font-family: $base-font-family;
  overflow-y: scroll;
  color: $base-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq($from: wide) {
    &.c-page-home,
    &.c-page-contact,
    &.c-page-about,
    &.c-page-projects {
      &::before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        right: 0;
      }
    }

    &.c-page-home,
    &.c-page-about,
    &.c-page-projects {
      &::before {
        width: 28px;
        height: 187px;
        background-color: #dedede;
      }
    }

    &.c-page-contact {
      &::before {
        width: 8px;
        height: 100px;
        background-color: $primary-color;
      }
    }
  }

  &.is-open {
    overflow: hidden;
  }
}

label,
input,
select,
button,
textarea {
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
strong {
  font-weight: 900;
}

a {
  color: $base-color;
}
