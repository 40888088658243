.c-jumbotron {
  width: 100%;
  height: 320px;
  background: no-repeat center center / cover;
  margin-bottom: 50px;

  @include mq($from: tablet, $until: desktop) {
    height: 420px;
  }

  @include mq($from: desktop) {
    height: 520px;
    margin-bottom: 130px;
  }
}
