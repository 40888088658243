.c-projects {
  margin-top: 80px;

  @include mq($from: desktop) {
    margin-top: 160px;
  }

  h2 {
    @include inuit-font-size(30px, 35px);
    margin-bottom: 40px;
    text-align: center;

    @include mq($from: desktop) {
      @include inuit-font-size(40px, 45px);
      margin-bottom: 60px;
    }
  }
}

.c-projects__footer {
  margin-top: 10px;
}
