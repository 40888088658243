.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-line-through {
  text-decoration: line-through;
}

.u-container {
  max-width: 1124px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  @include mq($from: desktop) {
    padding-right: 52px;
    padding-left: 52px;
  }
}

.u-container--small {
  max-width: 1024px;
}
