.c-slider {
  height: 368px;

  @include mq($from: tablet, $until: desktop) {
    height: 468px;
  }

  @include mq($from: desktop) {
    height: 568px;
  }

  .flickity-page-dots {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    right: 0;
    bottom: 0;
    text-align: left;
    height: 9px;
    margin-top: 20px;
    transform: translateY(34px);

    .dot {
      display: block;
      width: 34px;
      height: 3px;
      border-radius: 0;
      opacity: 1;
      background: #eee;
      transition: all 0.3s ease;
      margin: 0;

      @include mq($from: desktop) {
        width: 54px;
      }

      + .dot {
        margin-left: 15px;
      }

      &.is-selected {
        height: 9px;
        background: #cacaca;
      }
    }
  }
}

.c-slider__item {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
