.c-close-menu {
  display: none;

  @at-root .is-open & {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    appearance: none;
    margin: 0;
    border: 0;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    background: $primary-color;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
}
