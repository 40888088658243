.c-project-teaser {
  display: block;
  margin-bottom: $inuit-global-spacing-unit;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($white-color, 0.45);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}
