.c-footer-logo {
  display: flex;
  align-items: center;
  text-align: left;

  @include mq($until: desktop) {
    margin-bottom: $inuit-global-spacing-unit;
    justify-content: center;
  }
}

.c-footer-logo__image {
  margin-right: 30px;
  min-width: 48px;
}

.c-footer-logo__text {
  @include inuit-font-size(14px, 24px);
  font-weight: bold;
}
