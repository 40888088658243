.c-primary-nav {

}

.c-primary-nav__menu {
  display: none;

  @include mq($until: desktop) {
    @at-root .is-open & {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: $black-color;
      overflow-y: auto;
      padding: 50px 30px;
    }
  }

  @include mq($from: desktop) {
    display: block;
  }
}

.c-primary-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include mq($from: desktop) {
    display: flex;
  }
}

.c-primary-nav__item {
  margin: 0;
  padding: 0;

  @include mq($until: desktop) {
    text-align: center;
  }

  + .c-primary-nav__item {
    @include mq($until: desktop) {
      margin-top: 10px;
    }

    @include mq($from: desktop) {
      margin-left: 70px;
    }
  }
}

.c-primary-nav__link {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;

  @include mq($until: desktop) {
    @include inuit-font-size(32px);
    color: $white-color;
  }

  @include mq($from: desktop) {
    @include inuit-font-size(16px);
  }

  &:hover {
    color: $primary-color;
  }

  &.is-active {
    color: $primary-color;
    font-weight: 700;
  }
}
