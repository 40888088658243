.c-fb-follow {
  @include inuit-font-size(12px, 12px);
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: $primary-color;
  color: $white-color;
  text-decoration: none;
  transform-origin: bottom left;
  transform: rotate(-90deg) translateY(100%);
  padding: 20px 30px;

  @include mq($from: desktop) {
    left: 0;
    padding-right: 59px;
  }
}

.c-fb-follow__icon {
  position: absolute;
  bottom: 50%;
  right: 20px;
  transform-origin: center center;
  transform: rotate(90deg) translateX(50%);
}

.c-fb-follow__label {
  display: none;

  @include mq($from: desktop) {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
