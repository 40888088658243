.c-body {
  @include inuit-font-size(16px, 26px);
  font-weight: 300;

  @include mq($from: desktop) {
    @include inuit-font-size(18px, 28px);
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
