.c-cta {
  @include inuit-font-size(20px, 30px);
  font-weight: 700;
  background: url('../img/wood-pattern.jpg') no-repeat center center / cover;
  text-align: center;
  color: $white-color;
  margin-top: 50px;
  padding: 50px 25px;

  @include mq($from: tablet, $until: desktop) {
    @include inuit-font-size(25px, 35px);
  }


  @include mq($from: desktop) {
    @include inuit-font-size(35px, 45px);
    margin-top: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  a {
    color: $white-color;
    text-decoration: none;
    font-weight: 500;
  }
}
