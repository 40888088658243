.c-show-menu {
  @include inuit-font-size(16px);
  text-transform: uppercase;
  font-weight: 500;
  appearance: none;
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  @include mq($from: desktop) {
    display: none;
  }
}
