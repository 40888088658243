.c-content {
  position: relative;
}

.c-content__label {
  @include inuit-font-size(20px);
  font-weight: 700;
  color: $primary-color;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 20px;

  @include mq($until: desktop) {
    text-align: center;
  }

  @include mq($from: desktop) {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100%) translateY(-40px);
    margin-bottom: 0;
  }
}
