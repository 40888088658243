.c-page-intro {
  max-width: 740px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  text-align: center;

  @include mq($from: desktop) {
    margin-bottom: 130px;
  }
}
