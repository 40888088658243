.c-page-footer {
  @include inuit-font-size(14px, 24px);
  font-weight: 500;
  background-color: $black-color;
  color: $white-color;
  padding-top: 50px;
  padding-bottom: 50px;

  @include mq($until: desktop) {
    text-align: center;
  }

  @include mq($from: desktop) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  a {
    color: inherit;
  }
}
